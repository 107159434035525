export const environment = {
  production: false,
  debug: true,
  nombre_aplicacion : "Cardif - Asistencia Online",
  filtro_aplicacion : false, 
  _ssp : 'i8eKn8r2GU68xkq2G0$nzcA^',
  api : {
    "uss_" : "U2FsdGVkX1/GVnB//BnSncyMca/aZ0LR06L2tehZSVI=",
    "pss_" : "U2FsdGVkX1/PqCqdfomRU8afqbTiy3l08q+4sjX4aUc=",
    "ull_" : "https://dev1.prosys.cl/~mvergara/mw_asistencia_rac/web/cardif/",
    "ull_com" : "https://dev1.prosys.cl/~mvergara/mw_asistencia_rac/web/",
  },
  contactdata: {
    telefono: "600 390 1000"
  },
  telefono: "tel: +56222665430",
  gmaps: {
    token: "AIzaSyDe45OzsTPacG05l8N4cdrRpvbzh4JfSf4",
    options: "&libraries=places",
    refresh: 30
  },
  gcaptcha: "6LenmqUZAAAAACkMkKjlM1xqnsHx0FAKqsb3zmpn", 
};