import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { AlertasService } from '../services/alertas.service';
import { environment } from '../../environments/environment';
const { Geolocation, Network } = Plugins;
export class GoogleMapsComponent {
    constructor(alert, modal) {
        this.alert = alert;
        this.modal = modal;
        this.telefono2 = environment.telefono;
        this.location = {
            coords: {
                lat: 0,
                lng: 0
            }
        };
    }
    ngOnInit() {
        this.getLocation().then(() => {
            this.iniciarMapa();
        }).catch(() => {
            this.alert.showAlerta("No logramos obtener su posición actual.", "", "");
            this.getIpLocation().then(r => {
                this.location.lat = r.latitude;
                this.location.lng = r.longitude;
                console.log(this.location);
            }).then(() => {
                this.iniciarMapa();
            });
        });
    }
    modalDismissClose() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modal.dismiss({
                dismissed: false
            })
                .then(() => {
                this.modal = null;
            });
        });
    }
    modalDismiss() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log(this.location);
            this.modal.dismiss({
                dismissed: true,
                direccion: this.direccion,
                coordenadas: this.location,
            })
                .then(() => {
                this.modal = null;
            });
        });
    }
    iniciarMapa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.createMap()
                .then(() => {
                this.addMark();
            });
        });
    }
    /** Si `s:boolean`  es `true` entonces no está recibiendo el resultado de un evento
     * en cambio recibe coordenadas duras.
     * Si es `false` recibirá el resultado de un evento
     * @param e Evento
     * @param s Flag
     * @async
     */
    moveMark(e, s = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.markref.setAnimation(google.maps.Animation.DROP);
            if (s) {
                this.markref.setPosition(e);
                this.location = e.toJSON();
            }
            else {
                this.markref.setPosition(e.latLng);
                this.reverseGmaps(e.latLng.toUrlValue()).then(r => {
                    if (r[0]) {
                        this.direccion = r[0].formatted_address;
                    }
                    else {
                        this.direccion = "Dirección no disponible, reintente.";
                    }
                });
                this.location = e.latLng.toJSON();
            }
        });
    }
    addMark() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var label = "A";
            if (this.destino) {
                label = "B";
            }
            if (this.destino && Object.keys(this.coord_origen).length !== 0) {
                console.log(this.coord_origen);
                this.markref2 = new google.maps.Marker({
                    position: {
                        lat: this.coord_origen.lat,
                        lng: this.coord_origen.lng
                    },
                    label: "A",
                    map: this.mapref
                });
            }
            this.markref = new google.maps.Marker({
                position: {
                    lat: this.location.lat,
                    lng: this.location.lng
                },
                zoom: 12,
                label: label,
                map: this.mapref,
                draggable: true,
                animation: google.maps.Animation.DROP,
                title: "Hola mundo"
            });
            google.maps.event.addListener(this.markref, "dragend", (e) => {
                this.moveMark(e);
            });
            google.maps.event.addListener(this.markref, "tilesloaded", (e) => {
                this.moveMark(e);
            });
            google.maps.event.addListener(this.markref, "position_changed", (e) => {
                // console.log(e);
            });
            /* finally */
            this.reverseGmaps(this.markref.position.toUrlValue())
                .then(r => {
                if (r[0]) {
                    this.direccion = r[0].formatted_address;
                }
                else {
                    this.direccion = "Dirección no disponible, reintente.";
                }
            });
        });
    }
    createMap() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const mapele = document.getElementById('map');
            const search = document.getElementById('pac-input');
            this.mapref = new google.maps.Map(mapele, {
                center: {
                    lat: this.location.lat,
                    lng: this.location.lng
                },
                zoom: 15,
                streetViewControl: false,
                clickableIcons: false,
                disableDefaultUI: true
            });
            /* search bar  */
            this.search = new google.maps.places.SearchBox(search);
            this.mapref.controls[google.maps.ControlPosition.TOP_LEFT].push(search);
            google.maps.event.addListener(this.mapref, 'bounds_changed', () => {
                this.search.setBounds(this.mapref.getBounds());
            });
            google.maps.event.addListener(this.search, 'places_changed', () => {
                let places = this.search.getPlaces();
                // console.log(places.length);
                if (places.length >= 1) {
                    this.direccion = places[0].formatted_address;
                    this.moveMark(places[0].geometry.location, true);
                    this.mapref.panTo(places[0].geometry.location);
                }
                else {
                    this.alert.showToast("", "No se encontró esa dirección", 2, "TOP", "dark");
                }
                console.log(places);
            });
            /* end search */
            /* listener */
            google.maps.event.addListener(this.mapref, 'click', (e) => {
                this.moveMark(e);
            });
        });
    }
    /** obtener gps  */
    getLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.location = yield Geolocation.getCurrentPosition({
                enableHighAccuracy: true
            });
            this.location = { lat: yield this.location.coords.latitude, lng: yield this.location.coords.longitude };
            console.log("Posición:", yield this.location);
        });
    }
    /** Obtener IP
     * @async
     */
    getIpLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res;
            try {
                res = yield fetch('https://freegeoip.app/json/');
                res = yield res.json();
            }
            catch (error) {
                res = { status: "fail" };
            }
            return res;
        });
    }
    /** Geocode Reverse
     * @param coordenadas {String} Coordenadas en formato de string.
     * @async
     */
    reverseGmaps(coordenadas) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                let coords = coordenadas.split(',', 2);
                coords = { lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) };
                let geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'location': coords
                }, (res, status) => {
                    if (status == "OK") {
                        console.log("Estado", status, "Resulados", res);
                        return resolve(res);
                    }
                    else {
                        return reject([]);
                    }
                });
                // console.log(coords);
            });
        });
    }
}
