import { Injectable } from '@angular/core';
import { AlertController, ToastController, NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor(
    private alert: AlertController,
    private toast: ToastController,
    private nav: NavController
  ) { }

  async showAlerta(
    header: string,
    sub: string,
    message: string,
    buttons: any = ['Entendido']
  ) {
    const alert = await this.alert.create({
      header: header,
      subHeader: sub,
      message: message,
      buttons: buttons,
      backdropDismiss: false
    });
    await alert.present();
  }

  /** Muestra un toast
   * @param {string} header Cabecera
   * @param {string} mensaje Mensaje del toast
   * @param {number} tiempo Tiempo en el que el mensaje estará disponible
   * @param {string} posicion posicion del toast, top, bottom, middle
   * @param {string} color por defecto "dark"
   */
  async showToast(
    header: string = "",
    mensaje: string,
    tiempo: number = 2,
    posicion: string = "top",
    color: string = "dark"
  ) {
    const toast = await this.toast.create({
      position: "top",
      color: color,
      message: mensaje,
      duration: tiempo * 1000,
      showCloseButton: true
    });
    await toast.present();
  }

  /** alerta de formulario  */
  async alertaFormuario() {
    this.showAlerta(
      "Error Formulario",
      "",
      "Debe llenar todos los campos antes de solicitar asistencia",
      ["Entendido"]
    );
  }

  /** Hace algo despues de terminar una solicitud
   * @param {boolean} type si retornó correctamente o no
   * @param {any} data .... mmm
   * @async
   */
  async solicitudFormulario(
    type: boolean = true,
    data: any = []
  ) {
    if (type) {
      await this.showAlerta(
        'Correcto',
        'Solicitud enviada correctamente',
        'Su numero de servicio es: ' + data['numero_servicio'],
        [{
          text: 'Entendido',
          handler: () => { return this.nav.navigateBack(['/seguimiento']); }
        }]
      );
    } else {
      this.showAlerta(
        'Ocurrió un Problema',
        'No se pudo realizar la solicitud ',
        data['message'],
        ['Entendido']
      )

    }
  }

  async alertaDireccion() {
    this.showAlerta(
      "Dirección inválida",
      "",
      "Debe ingresar una dirección más detallada",
      ["Entendido"]
    );
  }
}
