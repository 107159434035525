import * as tslib_1 from "tslib";
import { AlertController, ToastController, NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class AlertasService {
    constructor(alert, toast, nav) {
        this.alert = alert;
        this.toast = toast;
        this.nav = nav;
    }
    showAlerta(header, sub, message, buttons = ['Entendido']) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header: header,
                subHeader: sub,
                message: message,
                buttons: buttons,
                backdropDismiss: false
            });
            yield alert.present();
        });
    }
    /** Muestra un toast
     * @param {string} header Cabecera
     * @param {string} mensaje Mensaje del toast
     * @param {number} tiempo Tiempo en el que el mensaje estará disponible
     * @param {string} posicion posicion del toast, top, bottom, middle
     * @param {string} color por defecto "dark"
     */
    showToast(header = "", mensaje, tiempo = 2, posicion = "top", color = "dark") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toast.create({
                position: "top",
                color: color,
                message: mensaje,
                duration: tiempo * 1000,
                showCloseButton: true
            });
            yield toast.present();
        });
    }
    /** alerta de formulario  */
    alertaFormuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showAlerta("Error Formulario", "", "Debe llenar todos los campos antes de solicitar asistencia", ["Entendido"]);
        });
    }
    /** Hace algo despues de terminar una solicitud
     * @param {boolean} type si retornó correctamente o no
     * @param {any} data .... mmm
     * @async
     */
    solicitudFormulario(type = true, data = []) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (type) {
                yield this.showAlerta('Correcto', 'Solicitud enviada correctamente', 'Su numero de servicio es: ' + data['numero_servicio'], [{
                        text: 'Entendido',
                        handler: () => { return this.nav.navigateBack(['/seguimiento']); }
                    }]);
            }
            else {
                this.showAlerta('Ocurrió un Problema', 'No se pudo realizar la solicitud ', data['message'], ['Entendido']);
            }
        });
    }
    alertaDireccion() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showAlerta("Dirección inválida", "", "Debe ingresar una dirección más detallada", ["Entendido"]);
        });
    }
}
AlertasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertasService_Factory() { return new AlertasService(i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i1.ToastController), i0.ɵɵinject(i1.NavController)); }, token: AlertasService, providedIn: "root" });
